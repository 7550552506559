.PlaySelector {
  .play-list {
    margin-top: 3px;
    background-color: white;
    max-height: 86px;
    overflow-x: hidden;
    overflow-y: scroll;
    border: solid thin black;
    width: 99.6%;

    .play-item {
      padding: 2px 4px;
      margin: 2px 0px;
      background-color: #f6f5f4;
      cursor: pointer;
    }

    .play-item.dirty {
      cursor: default;
    }
    .play-item.dirty:hover {
      background-color: #f6f5f4;
      color: #032a79;
    }

    .play-item:hover {
      background-color: #ffffff;
      color: #032a79;
    }

    .selected {
      background-color: #dfe2e3;
      font-weight: bolder;
    }

    .play-item.selected:hover {
      color: black;
      background-color: #dfe2e3;
    }
  }

  .playbook-buttons {
    margin-top: 5px;
  }

  input {
    width: 98%;
  }

  button {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 5px;
  }
}