$primary-background: #679BEF;

.App {
  .content {
    margin-top: 10px;
    padding: 10px;
    background-color: $primary-background;
    max-width: 400px;

    .label {
      font-weight: bold;
      margin-top: 10px;
    }

    .label:nth-of-type(1) {
      margin-top: 0px;
    }
  }

  .content:nth-of-type(1) {
    margin-top: 0px;
  }

  .main-content {
    position: relative;
    top: 10px;
    left: 20px;
    margin-bottom: 45px;

    .right-side {
      margin-left: 10px;
      position: absolute;
      top: 0px;
    }

  }

  user-select: none;

  .BottomNavigation {
    background-color: $primary-background;
  }

  .TopNavigation {
    height: 40px;
    margin-bottom: 5px;
    background-color: $primary-background;
  }

  button {
    padding: 5px 5px 5px 5px;
  }
}
