.StaticContent {
  font-size: 14px;
  background-color: #F2EBD5;
  padding: 5px 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  .static-content-title {
    font-weight: bold;
    font-size: 16px;
  }

  .info-heading {
    margin: 0px;
    font-weight: bold;
  }

  ul {
    li:first-child {
      margin-top: -9px;
    }
  }

  .link-description {
    padding-top: 20px;
    padding-bottom: 5px;
  }

  .link-description:first-of-type {
    padding-top: 5px;
  }
}