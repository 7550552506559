.CurrentPlay {
  width: 440px;
  input {
    margin-top: 5px;
    width: 98%;
  }

  textarea {
    margin-top: 5px;
    height: 50px;
    width: 98%;
  }

  button {
    margin-top: 5px;
    margin-right: 5px;
  }
}