.BottomNavigation {
  position: relative;
  top: 20px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .fine-print {
    position: relative;
    font-size: 12px;
    margin-left: auto;
    margin-right: 20px;
    span {
      padding-left: 20px;
    }
  }
}