.LabelEditor {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;

  .column {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
  }

  button {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .column-title {
    font-weight: bold;
  }
}