.link {
  padding-left: 20px;
  .link-text {
    text-decoration: none;
    padding: 3px 5px;
    border-radius: 8px;
    color: black;
  }

  .link-text:hover {
    background-color: #E1EAFE;
  }
}

.ContentLink {
  margin-top: -3px;
}