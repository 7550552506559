.PlayAnimator {
  background-repeat: no-repeat;
  touch-action: none;
  .element {
    touch-action: auto;
    position: absolute;
    width: 20px;
    height: 20px;
    .label {
      position: relative;
      top: 10px;
      left: 14px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .snapshot-description {
    touch-action: auto;
    position: absolute;
    width: 180px;
    min-height: 30px;
    padding: 6px;
    font-size: 12px;
    background-color: #f2ebd5;
    border: double 6px #c9a93e;
    border-radius: 8px;
  }

  .selected {
    border: black solid 1px;
    border-radius: 10px;
  }

  .editable {
    cursor: pointer;
  }

  svg {
    path {
      stroke: black;
      stroke-width: 2px;
    }
  }
}