.PlayEditor {
  text-align: center;
  button, span {
    margin-bottom: 5px;
    margin-right: 5px;
  }
  input {
    width: 98%;
    margin-bottom: 4px;
  }
}