.PlaybackControl {
  text-align: center;
  button, span {
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .edge-play-pause {
    position: absolute;
    left: -88px;
    top: 2px;
  }
}