.TopNavigation {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .right-links {
    display: flex;
    flex-direction: row;
    padding-left: 50px;
  }

  .page-title {
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-decoration: none;
  }

  .page-sub-title {
    font-weight: bold;
    font-size: 10px;
  }

}